/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CompanyDocumentConfig = {
  properties: {
    id: {
      type: 'string',
    },
    type: {
      type: 'CompanyDocumentType',
      isRequired: true,
    },
    documentMaxSize: {
      type: 'number',
      isRequired: true,
    },
    fileNameMaxChars: {
      type: 'number',
      isRequired: true,
    },
    description: {
      type: 'string',
      isRequired: true,
    },
    allowedFileTypes: {
      type: 'array',
      contains: {
        type: 'string',
      },
      isRequired: true,
    },
    required: {
      type: 'boolean',
      isRequired: true,
    },
    groupName: {
      type: 'string',
      isRequired: true,
    },
    active: {
      type: 'boolean',
      isRequired: true,
    },
    longDescription: {
      type: 'string',
    },
  },
} as const;
